:host,
:host *,
:host *::before,
:host *::after {
  box-sizing: border-box;
}

:host {
  display: block;
}

:host([clamp]) {
  display: -webkit-box;
  -webkit-line-clamp: var(--clamp, 3);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

:host([align="left"]) {
  text-align: left !important;
}

:host([align="left"]) ::slotted(*) {
  margin-right: auto !important;
}

:host([align="center"]) {
  text-align: center !important;
}

:host([align="center"]) ::slotted(*) {
  margin-right: auto !important;
  margin-left: auto !important;
}

:host([align="right"]) {
  text-align: right !important;
}

:host([align="right"]) ::slotted(*) {
  margin-left: auto !important;
}

:host ::slotted(midwest-button) {
  margin-top: 1rem;
  display: inline-block;
}

:host ::slotted(img),
:host ::slotted(midwest-code) {
  margin-bottom: 2rem;
}

:host ::slotted(*) {
  margin-bottom: 0.125em !important;
}

:host ::slotted(*:last-child) {
  margin-bottom: 0 !important;
}

:host([full]) ::slotted(*) {
  max-width: 100%;
}

:host ::slotted(ul),
:host ::slotted(ol) {
  margin: 0.25em 0;
  display: inline-block;
  text-align: left;
}

:host ::slotted(li) {
  margin-top: 0.5em;
}